import React, { createContext, useState, useContext } from 'react'

// Create a Loading Context
const LoadingContext = createContext()

// Loading Provider to wrap the app
export const LoadingProvider = ({ children }) => {
  const [loading, setLoading] = useState(false)

  return (
    <LoadingContext.Provider value={{ loading, setLoading }}>
      {children}
    </LoadingContext.Provider>
  )
}

// Custom hook to use the Loading Context
export const useLoading = () => useContext(LoadingContext)
