import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { setAccessToken } from '../services/storage'
import { ENDPOINTS, REFRESH_TOKEN } from '../Api'
import { apiCall } from '../services/commonApi'

const TokenRefresher = () => {
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    const getToken = async () => {
      setLoading(true)
      try {
        const response = await apiCall(
          'POST',
          ENDPOINTS.channelAdivsorTokenRefresh,
          {
            refreshToken: REFRESH_TOKEN,
            clientId: '3vft8vx6fr0x3h5d9g02r4ls47pgpjxp',
          },
        )
        setAccessToken(response?.access_token)
        localStorage.setItem('tokenrefresherTime', Date.now())
      } catch (error) {
        toast.error(error.message || 'Error refreshing token')
      } finally {
        setLoading(false)
      }
    }
    const cachedTime = localStorage.getItem('tokenrefresherTime')
    if (cachedTime && Date.now() - cachedTime < 3598 * 1000) {
    } else {
      getToken()
    }
  }, [])

  return <div></div>
}

export default TokenRefresher
