import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import en from './locales/en.json'
import hi from './locales/hi.json'
import fr from './locales/fr.json'

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    compatibilityJSON: 'v3',
    fallbackLng: 'en',
    detection: {
      order: [
        'htmlTag',
        'querystring',
        'cookie',
        'localStorage',
        'sessionStorage',
        'navigator',
        'path',
        'subdomain',
      ],
      caches: ['cookie'],
    },
    resources: {
      en,
      hi,
      fr,
    },
    interpolation: {
      escapeValue: false,
    },
  })

export const translate = str => {
  return i18n.t(str)
}

export default i18n
