// src/authContext.js
import React, { createContext, useContext, useState } from 'react'

const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    const savedUserType = localStorage.getItem('user_type')
    return savedUserType ? { user_type: JSON.parse(savedUserType) } : null
  }) // User object will have role property
  const [isImpersonating, setIsImpersonating] = useState(false)
  const [permissions, setPermissions] = useState(() => {
    const permission = localStorage.getItem('permission')
    return permission ? JSON.parse(permission) : null
  })
  const login = userData => {
    setUser(userData)
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        login,
        isImpersonating,
        setIsImpersonating,
        permissions,
        setPermissions,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => useContext(AuthContext)
