import axios from 'axios'
import { error } from 'jquery'
import Cookie from 'js-cookie'

// Base URL for the API
const BASE_URL = 'https://swipetoshare.com/api/'
export const TOKEN = localStorage.getItem('accessToken')
export const REFRESH_TOKEN = '2MWBpodhb-1yf63qB8iOrkykrsuP3G98KAqkbV0fwwo'
// API endpoints
export const ENDPOINTS = {
  getPermissionList: 'permissions/list',
  getAllPermissions: '/getAllPermissions',

  createPermission: '/permissions/create',
  getpermissionsbyid: '/getpermissionsbyid',
  updatePermission: '/permissions/update',
  deletePermission: '/permissions/delete',

  //Channel Adivsor Token
  channelAdivsorTokenRefresh: 'channeladvisor/oauth2/token',

  //login
  login: '/login',
  verifyEmail: '/verify-email',
  loginAsSupplier: '/suppliers/login-as-suppliers',
  stopImpersonation: 'suppliers/stop-impersonation',
  emailverificationCode: 'send-code',
  codeVerify: 'verify-code',
  register: 'register',

  //SuperAdmin
  getSuperAdminDetail: 'superadmin/view',
  //roles
  getRoles: '/roles/list',
  createRoles: '/roles/create',
  updateRoles: '/roles/update',
  getRolesByID: '/getRolesByID',
  deleteRoles: '/roles/delete',

  //brands
  getBrands: '/brand/list',
  createBrands: '/brand/create',
  updateBrands: '/brand/update',
  getBrandsByID: '/getBrandsByID',
  deleteBrands: '/brand/delete',

  //Category
  getCategory: '/categories/list',
  createCategory: '/categories/create',
  updateCategory: '/categories/update',
  getCategoryByID: '/getCategoryByID',
  deleteCategory: '/categories/delete',
  updateCategoryStatusByID: '/updateCategoryStatusByID',

  //Country
  getCountry: 'country/list',
  getCountryByID: 'getCountryByID',
  createCountry: '/country/create',
  updateCountry: 'country/update',
  deleteCountry: 'country/delete',

  //State
  getState: '/state/list',
  createState: '/state/create',
  updateState: '/state/update',
  getStateByID: '/getStateByID',
  deleteState: '/state/delete',
  getStateBycountryID: 'getStateBycountryID',

  //City
  getCity: '/city/list',
  createCity: '/city/create',
  updateCity: '/city/update',
  getCityID: '/getCityID',
  deleteCity: '/city/delete',

  //Promotions
  getListOfPromotions: 'promotions/list',
  createPromotion: 'promotions/create',
  getPromotionByID: 'promotions/getPromotionByID',
  updatePromotion: 'promotions/update',
  deletePromotion: 'promotions/delete',

  //MarketPlace-Inventory
  getMarketPlaceInventoryList: 'marketplace-inventory/list',
  deleteMarketplaceInventory: 'marketplace-inventory/delete',
  getMarketplaceInventoryByID:
    'marketplace-inventory/getMarketplaceInventoryByID',

  //Geolocation API
  geoLocation: 'integrations/getGeocode',

  //Currency Converter API
  currencyRateConverter: 'integrations/currencyRateConverter',

  //Pronorm Agent
  proAgentRegister: 'proagentregister',
  proAgentList: 'proagent/list',
  deleteProAgent: 'proagent/delete',
  proNormSupplierList: 'proagent/supplierslist',

  //Integrations
  getListOfIntegrations: 'integrations/list',
  deleteIntegration: 'integrations/delete',
  getIntegrationsByID: 'integrations/getIntegrationsByID',
  createIntegration: 'integrations/create',
  updateIntegration: 'integrations/update',

  //Suppliers
  getSupplierByStatus: '/getSupplierByStatus',
  addSupplier: 'suppliers/create',
  deleteSupplier: 'suppliers/delete',
  getSupplierById: 'getSupplierID',
  updateSupplierDetail: 'suppliers/update',
  addAdminSupplierDeail: 'admin/suppliers/create',
  updateAdminSupplierDetail: 'admin/suppliers/update',

  //SuperAdmin
  getSuperAdminList: 'superadmin/list',
  getSuperAdminById: 'getSuperadminID',
  deleteSuperAdmin: 'superadmin/delete',
  addSuperAdmin: 'superadmin/create',
  updateSuperAdminDetail: 'superadmin/update',

  //Affiliate
  getAffiliateList: 'affiliate/list',
  getAffiliateById: 'getAffiliateID',
  deleteAffiliate: 'affiliate/delete',
  addAffiliate: 'affiliate/create',
  updateAffiliate: 'affiliate/update',

  //Ticket-Category
  getTicketCategory: 'ticket-categories/list',
  deleteTicketCategory: 'ticket-categories/delete',
  getTicketCategoryByID: 'getTicketCategoryByID',
  addTicketCategory: 'ticket-categories/create',
  updateTicketCategory: 'ticket-categories/update',

  //Ticket-Priority
  getTicketPriority: 'ticket-priorities/list',
  deleteTicketPriority: 'ticket-priorities/delete',
  getTicketPriorityByID: 'getTicketPriorityByID',
  addTicketPriority: 'ticket-priorities/create',
  updateTicketPriority: 'ticket-priorities/update',

  //marketplace
  getMarketplace: '/marketplace/list',
  getMarketplaceByID: '/getMarketplaceID',
  createMarketplace: '/marketplace/create',
  updateMarketplace: '/marketplace/update',
  deleteMarketplace: '/marketplace/delete',

  //MarketPlace Inventory
  createMarketplaceInventory: 'marketplace-inventory/create',
  updateMarketplaceInventory: 'marketplace-inventory/update',

  //shippingcarriers
  getAllShippingCarriers: '/getAllShippingCarriers',
  getShippingCarriers: '/shipping-carriers/list',
  createShippingCarriers: '/shipping-carriers/create',
  updateShippingCarriers: '/shipping-carriers/update',
  deleteShippingCarriers: '/shipping-carriers/delete',
  getShippingCarriersByID: '/getShippingCarriersByID',

  //shippingcarriers
  getShippingServices: '/shipping-services/list',
  createShippingServices: '/shipping-services/create',
  updateShippingServices: '/shipping-services/update',
  deleteShippingServices: '/shipping-services/delete',
  getShippingServiceByID: '/getShippingServiceByID',

  //Fulfillment
  getFulfillment: '/fulfillment-users/list',
  createFulfillment: '/fulfillment-users/create',
  updateFulfillment: '/fulfillment-users/update',
  getFulfillmentID: '/getFulfillmentID',
  deleteFulfillment: '/fulfillment-users/delete',

  //product
  getProducts: '/product/list',
  createProducts: '/product/create',
  updateProducts: '/product/update',
  deleteProducts: '/product/delete',
  getProductID: '/getProductID',
  getAllProduct: 'getAllProduct',

  //Master
  getAllCity: 'getAllCity',
  getAllState: 'getAllState',
  getAllSuppliers: 'getAllSuppliers',
  getAllBrands: 'getAllBrands',
  getAllProductStatus: 'getAllProductStatus',
  getAllCountry: 'getAllcountry',
  getAllMarketplace: 'getAllMarketplace',
  getAllMainCategories: 'getAllMainCategories',
  getAllWarehouse: 'getAllWarehouse',

  //Warehouses
  getWarehouses: 'warehouse/list',

  //Supplier Panel Api Call

  //Supplier StepForm Api Call
  companyProfile: 'suppliers/profile/company_profile',
  getUserProfileById: 'getUserProfileById',
  uploadProducts: 'suppliers/profile/upload-products',
  //Product
  getProductbySupplier: 'getProductbySupplier',
  getSuppliersProductList: 'suppliers/product-list',
  supplierProductUpload: 'suppliers/upload-products',
  supplierImageUpload: 'suppliers/upload-image',
  downloadProductFile: 'suppliers/downlaod-product',
  getAllImages: 'suppliers/get-all-images',
  getAllProductFileList: 'suppliers/product-files-list',
  getAllProductExcel: 'suppliers/download-suppliers-products',
  deleteImage: 'suppliers/delete-image',
  deleteProfileProductFile: 'suppliers/profile/delete-products-by-file',
  deleteProfileMultipleProductFile: 'suppliers/profile/product-multiple-delete',

  //for supplier steps
  downloadProfileProductFile: 'suppliers/profile/downlaod-product',
  getImageBySupplierId: 'suppliers/profile/get-all-images',
  uploadProfileStepImages: 'suppliers/profile/upload-image',
  deleteProfileStepImages: 'suppliers/profile/delete-image',
  getSuppliersProfileProductList: 'suppliers/profile/product-list',
  getSupplierProfileStatusById: 'getSupplierProfileStatusById',
  supplierDashboardDetail: 'suppliers/dashboard',

  //Currency
  getAllCurrency: 'getAllCurrency',

  //WareHouse
  getWareHouseList: 'warehouse/list',
  createWareHouse: 'warehouse/create',
  deleteWareHouse: 'warehouse/delete',
  getWarehouseID: 'getWarehouseID',
  updateWarehouseDetail: 'warehouse/update',
  deleteMultipleWareHouse: 'warehouse/multiple_delete',

  ///Retailer and Teritory
  sendTeritoryMail: 'send-territory-email',

  addProfileWarehouse: 'suppliers/profile/warehouse-create',
  profileWarehouseList: 'suppliers/profile/warehouse-list',
  profilegetWarehouseID: 'suppliers/profile/getWarehouseID',
  editProfileWarehouse: 'suppliers/profile/warehouse-update',

  //warehouse Inventory
  getWareHouseInventory: 'warehouse-inventory/list',
  getWareHouseInventoryListById: 'warehouse-inventory/GetInventoryListById',
  downloadWarehouseInventory:
    'warehouse-inventory/downlaod-warehouse-inventory',
  uploadWareHouseInventory: 'warehouse-inventory/upload-warehouse-inventory',
  deleteWareHouseInventory: 'warehouse-inventory/delete',
  deleteWareHouseMultipleInventory: 'warehouse-inventory/multiple_delete',
  UpdateInventoryQuantityById:
    'warehouse-inventory/UpdateInventoryQuantityById',
  downloadProfileWarehouseInventory:
    'suppliers/profile/downlaod-warehouse-inventory',
  uploadProfileWareHouseInventory:
    'suppliers/profile/upload-warehouse-inventory',
  getProfileWareHouseInventoryListById:
    'suppliers/profile/GetInventoryListById',
  updateProfileInventoryQuantityById:
    'suppliers/profile/UpdateInventoryQuantityById',

  //Support
  supportTickets: 'support_tickets/getSupportTicketBySupplierID',
  getTicketDetailById: 'support_tickets/getSupportTicketByID',
  createSupportTicket: 'support_tickets/create',
  updateSupportTicket: 'support_tickets/update',
  deleteSupportTicket: 'support_tickets/delete',

  //Order
  orderList: 'orders/list',

  //Rithum Api Call

  getRithumProducts: '/Products',
  rithumProductUpload: 'rithum/ProductUpload',
  rithumOrderDetails: 'Orders',
  rithumShippingRates: 'orders/shippingrates',
  getErrorRithumProductUpload: 'token/ProductUpload',
  rithumPurchaseLabel: 'orders/PurchaseLabel',
  rithumFulfillments: 'Fulfillments',
  rithumProductUploadError: 'token/ProductUploaderrorbytoken',
  rithumDistributionCenter: 'DistributionCenters',
  rithumProductImages: 'Images',
}

// Create an Axios instance
// const apiInstance = axios.create({
//   baseURL: BASE_URL,
//   timeout: 10000,
//   headers: {
//     'Content-Type': 'application/json',
//   },
// })
// apiInstance.interceptors.request.use(
//   config => {
//     const token = Cookie.get('userToken')
//     if (token) {
//       config.headers['Authorization'] = `Bearer ${token}`
//     }
//     return config
//   },
//   error => {
//     // Handle the error
//     return Promise.reject(error)
//   },
// )

// export default apiInstance
