import React from 'react'
import AppRoutes from './router' // Import the routes from router.js
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'react-loading-skeleton/dist/skeleton.css'
import { CurrencyProvider } from './context/CurrencyContext'
import TokenRefresher from './layouts/TokenRefresher'

function App() {
  return (
    <>
      <CurrencyProvider>
        <ToastContainer />
        <AppRoutes />
        <TokenRefresher />
      </CurrencyProvider>
    </>
  )
}

export default App
